.slideList {
  width: 314px;
  background-color: rgba(254, 254, 254, 1);
  overflow: hidden;
  margin: 0;
  flex-shrink: 0;
}
.slideList .slide_link {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
/* 一级菜单样式 */
.slideList .firstMenu {
  position: relative;
  height: 72px;
  padding-left: 39px;
  color: #666666;
  font-size: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
}
.slideList .firstMenu.active {
  color: #333;
}
.slideList .firstMenu .slide_icon {
  width: 22px;
  margin-right: 14px;
  box-sizing: border-box;
}
.slideList .firstMenu .open_icon {
  width: 12px;
  position: absolute;
  right: 24px;
  transform: rotate(180deg);
  transition: all .3s;
}
.slideList .firstMenu .open_icon.open {
  transform: rotate(0deg);
}
/* 二级菜单样式 */
.slideList .sub_slides {
  font-size: 16px;
  color:#333333;
  overflow: hidden;
  transition: all .3s;
  height: 0;
}
.slideList .sub_slides li{
  background-color: rgba(255, 0, 70, 0.02);
  position: relative;
}
.slideList .sub_slides li.active {
  background-color: #EFF6FF;
  color: #2B9DFF;
}
.slideList .sub_slides li.active::after {
  position: absolute;
  content:'';
  right: 0;
  top: 0;
  width: 3px;
  height: 100%;
  background-color: #2B9DFF;
}
.slideList .sub_slides .slide_link {
  width: 100%;
  box-sizing: border-box;
  padding-left: 75px;
  height: 56px;
}