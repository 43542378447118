.qualification_main .model_title {
    margin-bottom: 50px;
}

.qualification_main .model_content_card_title {
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
    letter-spacing: 1px;
    padding-left: 163px;
    margin-bottom: 30px;
}

.qualification_main .model_content_main_title1 {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    letter-spacing: 1px;
    margin-bottom: 13px;
}

.qualification_main .model_content_main_title1 span {
    color: #FC3455;
}

.qualification_main .business_license_icon {
    width: 231px;
    height: 190px;
    margin-left: 22px;
}

.qualification_main .upload_content_main {
    width: 544px;
}

.qualification_main .upload_content {
    float: left;
    margin-left: 22px;
}

.qualification_main .upload_content:first-child {
    margin-left: 0;
}

.qualification_main .identity_card_icon {
    width: 438px;
    height: 190px;
    margin-top: 22px;
}