.photo_libray {
  background-color: #fff;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.photo_libray img {
  width: 100%;
}
.photo_libray i {
  text-decoration: none;
  font-style: normal;
}
.photo_libray .content_block {
  padding-left: 24px;
  padding-right: 24px;
}
.photo_libray .libray_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
  border-bottom: 1px solid #F9F9F9;
}
.photo_libray .libray_head .libray_page_title {
  color: #333333;
  font-size: 20px;
  font-family: '苹方-简 中黑体';
  font-weight: 500;
}
.photo_libray .libray_btn_big {
  padding:0 21px;
  height:46px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 16px;
  font-family: '苹方-简 常规体';
  font-weight: 400;
  cursor: pointer;
  user-select: none;
}
.photo_libray .libray_btn_icon {
  width: 16px;
  margin-right: 10px;
}
.photo_libray .libray_btn_big.add {
  background-color: #FC3455;
  color:#fff;
  margin-right: 12px;
}
.photo_libray .libray_btn_big.static {
  background-color: #fff;
  border:1px solid #C0C0C0;
}
.photo_libray .libray_info {
  font-size: 14px;
  color: #666666;
  font-family: '苹方-简 常规体';
  padding-top: 10px;
}
.photo_libray .libray_ctrl {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
}
.photo_libray .all_info {
  display: flex;
  align-items: center;
}

.photo_libray .select_count {
  font-size: 16px;
  color: #333333;
  font-family: '苹方-简 常规体';
}
.photo_libray .select_count i {
  color:#2B9DFF;
}
.photo_libray .libray_btn_small {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width:78px;
  height: 38px;
  border-radius: 6px;
  font-family: '苹方-简 常规体';
  font-size: 16px;
  cursor: pointer;
  user-select: none;
}
.photo_libray .libray_btn_small.cancel {
  border:1px solid #2B9DFF;
  background-color: #fff;
  color: #2B9DFF;
  margin-right: 10px;
}
.photo_libray .libray_btn_small.conmit {
  background-color:#2B9DFF;
  color:#fff;
}
.photo_libray .empty_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

}
.photo_libray .empty_info .empty_icon {
  width:161px;
  margin-bottom: 34px;
}
.photo_libray .empty_info p {
  font-size: 20px;
  color: #999999;
  font-family: '苹方-简 常规体';
}
.photo_libray .libray_list {
  padding-top: 15px;
}
.photo_libray .libray_list .photo_item {
  width: 1.0625rem;
  border:1px solid #ccc;
  padding:0.0521rem 0.1094rem 0.0677rem;
  box-sizing: border-box;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 0.1667rem;
  border-radius: 0.0208rem;
  overflow: hidden;
  cursor: default;
  position: relative;
} 
.photo_libray .libray_list .photo_item:hover{
  background-color: #E6E6E6;
}
.photo_libray .libray_list .photo_item:not(:nth-child(6n)){
  margin-right: 0.2604rem;
}
.photo_libray .libray_list .photo_item .ant-checkbox-wrapper {
  position: relative;
  z-index: 8;
}
.photo_libray .libray_list .photo_item .item_mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(0,0,0,.5); */
  z-index: 6;
}
.photo_libray .libray_list .photo_item .photo_preview {
  margin-top: 0.0573rem;
  box-shadow: 0px 1px 0.0260rem 0px rgba(0, 0, 0, 0.5);
  position: relative;
  width: 0.8438rem;
  height: 0.8438rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo_libray .libray_list .photo_item  .remove_icon {
  width: 0.0573rem;
  height: 0.09375rem;
  cursor: pointer;
  padding: 0.0208rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  box-sizing: content-box;
  position: relative;
  z-index: 7;
}
.photo_libray .libray_list .photo_item .prev_main {
  position: relative;
  z-index: 2;
}
.photo_libray .libray_list .photo_item .prev_bg {
  position: absolute;
  filter: blur(3px);
}
.photo_libray .uploadInfo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.5);
  color: #fff;
  z-index: 8;
}
.photo_libray .uploadInfo .ant-progress-text {
  color: #fff;
}
.photo_libray .libray_list .photo_item .photo_name {
  margin-top: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}
.photo_libray .libray_pagination {
  padding-top: 60px;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 60px;
}
