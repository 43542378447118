.editable-cell {
  position: relative;
}

.edit_cell {
  padding: 5px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.edit_cell .cell_value_holder {
  flex-grow: 1;
  border:1px solid transparent;
  padding: 4px 11px;
  height: 32px;
}

.editable-row:hover .edit_cell .cell_value_holder {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  margin-right: 10px;
}

[data-theme='dark'] .editable-row:hover  {
  border: 1px solid #434343;
}