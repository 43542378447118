.goods_mange {
  background-color: #fff;
  border-radius: 4px;
  padding-right: 12px;
  height: 100%;
}
.goods_mange .header_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom:1px solid #F9F9F9;
  padding: 17px 24px;
}
.goods_mange .header_info .page_title {
  font-size: 20px;
  color: #333;
  font-weight: 500;
  font-family: '苹方-简 中黑体';
}
.goods_mange .header_info .add_goods_btn {
  background-color: #FC3455;
  color:#fff;
  font-size: 16px;
  font-weight: 400;
  font-family: '苹方-简 常规体';
  width: 130px;
  height: 46px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.goods_mange .header_info .add_good_btn_text {
  margin-left: 10px;
}
.goods_mange .filter_info {
  padding:25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.goods_mange .filter_info .status_filter {
  border:1px solid #F4F4F4;
}
.goods_mange .filter_info .status_filter .ant-radio-button-wrapper {
  font-size: 16px;
  color:#333333;
  font-family: '苹方-简 中黑体';
  font-weight: 500;
  padding: 0 56px;
  height: 46px;
  line-height: 46px;
  border:none;
}
.goods_mange .filter_info .status_filter .ant-radio-button-wrapper::before {
  background-color:#F4F4F4;
}
.goods_mange .filter_info .status_filter .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{
  background-color: #EFF6FF;
  color: #2B9DFF;
}
.goods_mange .filter_info .search_filter{
  display: flex;
  align-items: center;
}
.goods_mange .filter_info .search_filter .search_input{
  width: 163px;
  height: 40px;
  background-color: #F4F4F4;
  font-size: 14px;
  font-family: '苹方-简 常规体';
  border:none;
  outline: none;
  padding: 0 20px;
  border-radius: 4px;
  margin-right: 10px;
}
.goods_mange .filter_info .search_filter .search_btn{
  padding: 0 21px;
  height: 40px;
  line-height: 40px;
  background-color: #FFF5F8;
  border-radius: 4px;
  border:1px solid #FC3455;
  color: #FC3455;
  font-size: 18px;
  font-family: '苹方-简 中黑体';
  font-weight: 500;
  cursor: pointer;
  user-select: none;
}
.goods_mange .table_data_info {
  padding: 0 24px;
}
.goods_mange .table_action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goods_mange .table_btn {
  width:70px;
  height: 36px;
  font-size: 16px;
  font-weight: 500;
  font-family: '苹方-简 中黑体';
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}
.goods_mange .table_btn.edit_btn {
  background-color: #2B9DFF;
  color:#fff;
}
.goods_mange .table_btn.off_btn {
  background-color: rgba(90, 171, 255, 0.1);
  border:1px solid #5AABFF;
  color: #5AABFF;
}
.goods_mange .shop_img {
  width: 100px;
  height: 100px;
}
.goods_mange .price {
  color: #2B9DFF;
  font-size: 14px;
  font-family: '苹方-简 常规体';
}
.goods_mange .status {
  font-size: 14px;
  font-family: '苹方-简 常规体';
  color:#333333;
}
.goods_mange .status .status_success {
  color: #2B9DFF;
}
.goods_mange .status .status_full {
  color: #FC3455;
}