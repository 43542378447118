.good_detail img {
  width: 100%;
}
.good_detail .detail_title{
  font-size: 20px;
  color: #333;
  font-family: '苹方-简 中黑体';
  font-weight: 500;
  padding-left: 23px;
}
.good_detail .detail_info_block {
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  margin-top: 30px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.02);
}
.good_detail .detail_info_block .info_block_title {
  margin-right: 11px;
  border-bottom: 1px solid #F9F9F9;
  padding: 30px 24px;
  font-size: 20px;
  color:#333;
  font-family: '苹方-简 常规体';
  font-weight: 400;
}
.good_detail .detail_info_block .input_list {
  padding-top: 18px;
  padding-bottom: 16px;
}
.good_detail .detail_info_block .input_item {
  display: flex;
  color: #101010;
  font-size: 16px;
  margin-bottom: 18px;
}
.good_detail .detail_info_block .input_item.center {
  align-items: center;

}
.good_detail .detail_info_block .input_item .input_item_title {
  margin-right: 28px;
  white-space: nowrap;
  width: 126px;
  text-align: right;
}
.good_detail .detail_info_block .must_item::before {
  content:'*';
  color: #FF0054;
  margin-right: 6px;
}
.good_detail .cell_img_holder {
  display: flex;
  justify-content: center;
}
.good_detail .cell_img_holder img {
  width: 56px;
  height: 56px;
}
.good_detail .cell_img_holder  .cel_upload_btn {
  width: 100%;
  border:1px dashed #DDDDDD;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  cursor: pointer;
  user-select: none;
}
.good_detail .detail_info_block .input_item .input_item_value {
  width: 420px;
  position: relative;
}
.good_detail .detail_info_block .input_item  .error_info {
  display:none;
  font-size: 14px;
  color: #FC3455;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(100%,-50%);
  padding-left: 10px;
}
.good_detail .detail_info_block .input_item .error {
  position: relative;
}
.good_detail .detail_info_block .input_item .error .ant-input,
.good_detail .detail_info_block .input_item .error .ant-input-affix-wrapper,
.good_detail .detail_info_block .input_item .error .ant-picker{
  border:1px solid #FC3455;
}
.good_detail .detail_info_block .input_item .error .error_info {
  display:block;
}
.good_detail .detail_info_block .input_item .input_more_value {
  width: 786px;
}
.good_detail .detail_info_block .input_item .more_item_value {
  width: 727px
}
.good_detail .detail_info_block .sku_add {
  margin-bottom: 25px;
  margin-left: 25px;
  width: 914px;
}
.good_detail .input_item_value.row {
  display: flex;
}
.good_detail .input_item_value .radio_row .radio_title {
  font-size: 14px;
  color: #333333;
  margin-right: 20px;
  white-space: nowrap;
  /* width: 140px; */
  overflow: hidden;
  text-align: right;
  flex-shrink: 0;
}
.good_detail .input_item_value .radio_row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
.good_detail .input_item_value .radio_row .ant-checkbox-wrapper,
.good_detail .input_item_value .radio_row .ant-radio-wrapper{
  width: 80px;
  white-space: nowrap;
}
.good_detail .check_block {
  width: 120px;
  height: 60px;
  border-radius: 6px;
  border:1px solid #BBBBBB;
  font-family: '苹方-简 常规体';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.good_detail .acive_icon {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 26px;
  opacity: 0;
}
.good_detail .check_block.active .check_box_subtitld{
  color:#2B9DFF;
}
.good_detail .check_block.active .acive_icon {
  opacity: 1;
}
.good_detail .check_block.active .check_box_title{
  color: #2B9DFF;
}
.good_detail .check_block.active {
  border-color:#2B9DFF;
}
.good_detail .check_block:nth-child(n+2) {
  margin-left: 30px;
}
.good_detail .check_block .check_box_title{
  color: #333333;
  font-size: 14px;
}
.good_detail .check_block .check_box_subtitld{
  color: #999999;
  font-size: 12px;
}
.good_detail .text_info {
  color: #5A5A5A;
  font-size: 14px;
  font-family: '苹方-简 常规体';
  margin-top: 16px;
}
.good_detail .text_info2 {
  color: #959595;
  font-size: 13px;
  font-family: '苹方-简 常规体';
}
.good_detail .pay_limit {
  margin-top: 24px;
  color:#333;
  font-size: 14px;
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.good_detail .upload_value {
  padding-top: 2px;
}
.good_detail .upload_info_text {
  font-size: 14px;
  color: #ADADAD;
  font-family: '苹方-简 常规体';
  margin-bottom: 22px;
}
.good_detail .upload_info_text .form_blue_color {
  color: #2B9DFF;
}
.good_detail .submit {
  margin-top: 59px;
  height: 100px;
  position: relative;
}
.good_detail .submit .btn_fixed{
  background-color: #fff;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -8px 10px 0px rgba(255, 0, 70, 0.02);
}
.good_detail .submit .submit_btn_item {
  width:320px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 18px;
  font-family: '苹方-简 常规体';
  font-weight: 400;
}
.good_detail .submit .submit_btn_item.ok {
  background-color: #2B9DFF;
  color: #fff;
}
.good_detail .submit .submit_btn_item.fail {
  border:1px solid #DDDDDD;
  background-color: #F9F9F9;
  color: #717171;
  margin-right: 24px;
}
.good_detail .cell_row {
  display: flex;
}
.good_detail .timer_select {
  display: flex;
  align-items: center;
  margin-top: 26px;
}
.good_detail .timer_select  .timer_title {
  margin-right: 10px;
  font-size: 16px;
  color:#101010;
}
.good_detail .table_cell_edit {
  color:#1890ff;
  cursor: pointer;
  user-select: none;
}