.merchant_main {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: calc(100vh - 80px);
    padding-bottom: 111px;
    background: url('../../static/imgs/register_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.merchant_main .box_main_model {
    width: 1200px;
    background: #FFFFFF;
    margin-top: 90px;
}

.merchant_main .box_main_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18px;
    margin: auto;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #999999;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
}

.merchant_main .model_title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 152px;
    background: #F6F6F6;
    margin-bottom: 90px;
}

.merchant_main .title_items {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.merchant_main .title_items_num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid #999999;
    border-radius: 50%;
    font-size: 23px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    line-height: 32px;
    letter-spacing: 1px;
}

.merchant_main .title_items_text {
    font-size: 23px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    line-height: 32px;
    letter-spacing: 1px;
    margin-top: 15px;
}

.merchant_main .title_items_active .title_items_num {
    color: #FFFFFF;
    border-color: #FC3455;
    background: #FC3455;
}

.merchant_main .title_items_active .title_items_text {
    color: #FC3455;
}

.title_items_active1 .title_items_num {
    border-color: #FC3455;
}

.title_items_active1 .title_items_num img {
    width: 28px;
    height: 20px;
}

.merchant_main .title_items_bg {
    width: 183px;
    height: 1px;
    background: #979797;
    margin-top: -45px;
}

.merchant_main .title_items_bg_active {
    background: #FC3455;
}

.merchant_main .model_content {
    display: flex;
    position: relative;
    width: 714px;
    margin: 0 auto;
    margin-bottom: 30px;
    overflow: hidden;
}

.merchant_main .model_content .model_content_title {
    width: 144px;
    text-align: right;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
    margin-top: 12px;
}

.merchant_main .model_content .model_content_title span {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FC3455;
    line-height: 25px;
    letter-spacing: 1px;
    margin-right: 6px;
}

.merchant_main .model_content1 .model_content_title {
    margin-top: 0;
}

.merchant_main .model_content_main {
    display: flex;
    margin-left: 26px;
}

.merchant_main .model_content_main1 {
    flex-direction: column;
}

.merchant_main .model_content_main_item {
    display: flex;
    flex-wrap: wrap;
    width: 544px;
}

.merchant_main .model_content_main_items {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 115px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #666666;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    letter-spacing: 1px;
    margin-top: 16px;
    margin-right: 28px;
    cursor: pointer;
    text-align: center;
}

.merchant_main .model_content_main_items:nth-child(4n) {
    margin-right: 0;
}

.merchant_main .model_content_main_items:hover {
    border-color: #FF7F94;
    color: #FC3455;
}

.merchant_main .model_content_main_items_active {
    background: rgba(252, 52, 85, 0.14);
    border-color: #FC3455;
    color: #FC3455;
}

.merchant_main .model_content_main_natures {
    width: 544px;
    margin-bottom: 8px;
}

.merchant_main .model_content_main_natures .natures_title {
    width: 90px;
    float: left;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 25px;
    letter-spacing: 1px;
}

.merchant_main .model_content_main_natures .natures_list {
    width: 454px;
    float: left;
    padding-left: 25px;
}

.merchant_main .model_content_main_natures .natures_inp {
    width: 429px;
    margin-bottom: 16px;
}

.merchant_main .model_content .model_content_main_title {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FC3455;
    line-height: 20px;
    letter-spacing: 1px;
}
.merchant_main .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
    margin-right: 8px;
    margin-bottom: 8px;
}
.merchant_main .img_point {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 21px;
    letter-spacing: 1px;
    margin-left: 12px;
}

.merchant_main .model_content_inp {
    width: 544px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    padding-left: 12px;
    outline: none;
    letter-spacing: 1px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
}

.merchant_main .model_content_inp::placeholder {
    color: #999999;
}

.merchant_main .model_content_inp2 {
    width: 390px;
    margin-left: 8px;
}

.merchant_main .model_content_textarea {
    width: 544px;
    height: 120px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    letter-spacing: 1px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    resize: none;
    padding-left: 12px;
    padding-top: 5px;
}

.merchant_main .model_content_textarea::placeholder {
    color: #999999;
}

.merchant_main .ant-radio-group {
    width: 544px;
}
.merchant_main .natures_list .ant-radio-group {
    width: 454px;
}
.merchant_main .ant-radio-wrapper {
    margin-right: 15px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
}

.merchant_main .model_content_inp1 {
    width: 275px;
}

.model_content_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 163px;
    margin-top: 83px;
}

.merchant_main .register_merchant_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 336px;
    height: 66px;
    border-radius: 10px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    cursor: pointer;
    background: #FC3455;
}

.merchant_main .register_merchant_btn:hover {
    opacity: 0.8;
}

.merchant_main .register_merchant_btn1 {
    border: 1px solid #FC3455;
    background: #FFFFFF;
    color: #FC3455;
    margin-right: 17px;
}

.merchant_main .ant-select {
    font-size: 18px;
}

.merchant_main .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 50px;
}

.merchant_main .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 48px !important;
}

/* .merchant_main .ant-radio-checked .ant-radio-inner {
    border-color: #FC3455;
}

.merchant_main .ant-radio:hover .ant-radio-inner {
    border: 1px solid#FC3455 !important;
}

.merchant_main .ant-radio-inner::after {
    background-color: #FC3455;
}

.merchant_main .ant-radio-checked::after {
    border: 1px solid #FC3455;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}