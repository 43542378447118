/* 字体定义 */
@font-face {
  font-family: '苹方-简 中黑体';
  src: url('./static/fonts/苹方-简-中黑体.ttf');
}
@font-face {
  font-family: '苹方-简 常规体';
  src:url('./static/fonts/苹方-简-常规体.ttf')
}
@font-face {
  font-family: 'PingFangSC-Medium';
  src: url('./static/fonts/苹方-简-中黑体.ttf');
}
@font-face {
  font-family: 'PingFangSC-Regular';
  src:url('./static/fonts/苹方-简-常规体.ttf')
}
#message {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 300;
  width: 60%;

}
p{
  margin: 0;
}

body {
  background-color: #FAFAFC;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
#root {
  height:100%;
}

.upload_dom {
  position: absolute;
  top: -9999px;
  left: -9999px;
  opacity: 0;
}