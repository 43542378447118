.register_main {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: calc(100vh - 80px);
    background: url('../../static/imgs/register_bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
}

.register_main .box_main_model {
    width: 1200px;
    height: 700px;
    background: #FFFFFF;
    z-index: 1;
}

.register_main .box_main_bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 18px;
    margin: auto;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #999999;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: center;
}

.register_main .model_title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 152px;
    background: #F6F6F6;
    margin-bottom: 90px;
}

.register_main .title_items {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.register_main .title_items_num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 1px solid #999999;
    border-radius: 50%;
    font-size: 23px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    line-height: 32px;
    letter-spacing: 1px;
}

.register_main .title_items_text {
    font-size: 23px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    line-height: 32px;
    letter-spacing: 1px;
    margin-top: 15px;
}

.register_main .title_items_active .title_items_num {
    color: #FC3455;
    border-color: #FC3455;
}

.register_main .title_items_active .title_items_text {
    color: #FC3455;
}

.register_main .title_items_bg {
    width: 183px;
    height: 1px;
    border: 1px solid #979797;
    margin-top: -45px;
}

.register_main .model_content {
    display: flex;
    align-items: center;
    position: relative;
    width: 581px;
    height: 50px;
    margin: 0 auto;
    margin-bottom: 30px;
    overflow: hidden;
}

.register_main .model_content span {
    width: 140px;
    text-align: right;
    margin-right: 26px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
    letter-spacing: 1px;
}

.register_main .model_content span span {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FC3455;
    line-height: 25px;
    letter-spacing: 1px;
    margin-right: 6px;
}

.register_main .model_content_inp {
    width: 425px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid #DBDBDB;
    padding-left: 12px;
    outline: none;
    letter-spacing: 1px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
}

.register_main .model_content_inp::placeholder {
    color: #999999;
}

.register_main .model_content_inp1 {
    width: 275px;
}

.register_main .get_code {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px;
    height: 49px;
    border-radius: 4px;
    border: 1px solid #FC3455;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FC3455;
    letter-spacing: 1px;
    margin-left: 14px;
    cursor: pointer;
}
.register_main .get_code_active {
    color: #999999;
    border-color: #999999;
}
.register_main .get_code:hover {
    opacity: 0.8;
}

.register_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 465px;
    height: 66px;
    border-radius: 10px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    margin: auto;
    margin-top: 50px;
    cursor: pointer;
    background: #FC3455;
}

.register_btn:hover {
    opacity: 0.8;
}