.ant-upload-list-picture-card-container {
  width: 190px !important;
  height: 190px !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 190px !important;
  height: 190px !important;
  background: #FBFBFB !important;
}
.glo_upload_img {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #DCDCDC;
  font-family: '苹方-简 常规体';
  font-weight: 400;
  cursor: pointer;
}
.glo_upload_img span {
  margin-top: 10px;
  font-size: 18px !important;
}
.glo_upload_img svg {
  font-size: 40px !important;
}